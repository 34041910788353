import styles from "components/Timeline/Timeline.module.scss";
import timeline from "lib/timeline.json";

const Timeline = () => {
  return (
    <div className={styles.timelineLayout}>
      <p className={styles.title}>Education & Work Experience</p>

      <div className={styles.timeline}>
        {timeline.map((item, index) => {
          if (index % 2 === 0) {
            return (
              <div className={`${styles.container} ${styles.left}`}>
                <div className={styles.timelineHeaderLayoutLeft}>
                  <p className={styles.duration}>{item.duration}</p>
                  <h2 className={styles.timelineHeader}>{item.name}</h2>
                </div>
                <p className={styles.descriptionLeft}>{item.description}</p>
              </div>
            );
          } else {
            return (
              <div className={`${styles.container} ${styles.right}`}>
                <div className={styles.timelineHeaderLayoutRight}>
                  <h2 className={styles.timelineHeader}>{item.name}</h2>
                  <p className={styles.duration}>{item.duration}</p>
                </div>
                <p className={styles.descriptionRight}>{item.description}</p>
              </div>
            );
          }
        })}
      </div>
    </div>
  );
};

export default Timeline;
