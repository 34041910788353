import About from "components/About/About";
import Contact from "components/Contact/Contact";
import Intro from "components/Intro/Intro";
import styles from "components/NewProfile/NewProfile.module.scss";
import ProjectsTiledView from "components/ProjectsTiledView/ProjectsTiledView";
import Timeline from "components/Timeline/Timeline";
import { useRef } from "react";

const NewProfile = () => {
  const myRef = useRef(null);
  const executeScroll = () => {
    myRef.current.scrollIntoView({ behavior: "smooth" });
  };

  return (
    <div className={styles.parentLayout}>
      <Intro executeScroll={executeScroll} />
      {/* <About myRef={myRef} />
      <ProjectsTiledView />
      <Timeline />
      <Contact /> */}
    </div>
  );
};

export default NewProfile;
