import ImageSlider from "components/ImageSlider/ImageSlider";
import styles from "components/ProjectInfoModal/ProjectInfoModal.module.scss";

const ProjectInfoModal = (props) => {
  const { project } = props;
  return (
    <div className={styles.modal}>
      <div className={styles.modalContent}>
        <div className={styles.modalHeader}>
          <button className={styles.close} onClick={props.closeModal}>
            &times;
          </button>
          <p className={styles.projectTitle}>{project.name}</p>
        </div>
        <div className={styles.projectCard}>
          <div className={styles.projectCardScreenShots}>
            <ImageSlider images={project.images} />
          </div>
          <div className={styles.projectCardDetails}>
            <br />
            <p className={styles.projectDetails}>{project.description}</p>
            <br />
            <table>
              <tr className={styles.tableRow}>
                <td className={styles.tableData}>
                  <p className={styles.projectDetails}>Technology</p>
                </td>
                <td className={styles.tableData}>
                  <p className={styles.projectDetails}>{project.technology}</p>
                </td>
              </tr>
              <tr className={styles.tableRow}>
                <td className={styles.tableData}>
                  <p className={styles.projectDetails}>Role</p>
                </td>
                <td className={styles.tableData}>
                  <p className={styles.projectDetails}>{project.role}</p>
                </td>
              </tr>
              <tr className={styles.tableRow}>
                <td className={styles.tableData}>
                  <p className={styles.projectDetails}>Responsibilities</p>
                </td>
                <td className={styles.tableData}>
                  <ul>
                    {project.responsibilities.map((responsibility) => (
                      <li className={styles.projectDetails}>
                        {responsibility}
                      </li>
                    ))}
                  </ul>
                </td>
              </tr>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProjectInfoModal;
