import ProjectInfoModal from "components/ProjectInfoModal/ProjectInfoModal";
import styles from "components/ProjectsTiledView/ProjectsTiledView.module.scss";
import projects from "lib/projects.json";
import { useState } from "react";

const ProjectsTiledView = () => {
  const [showModal, setShowModal] = useState(false);
  const [projectInfo, setProjectInfo] = useState(null);

  const handleProjectClick = (project) => {
    setShowModal(true);
    setProjectInfo(project);
    const body = document.body;
    body.style.height = "100vh";
    body.style.overflowY = "hidden";
  };

  const closeModal = () => {
    setShowModal(false);
    const body = document.body;
    body.style.height = "";
    body.style.overflowY = "";
  };

  return (
    <div className={styles.layout}>
      <p className={styles.title}>Projects</p>
      <div className={styles.gridDisplay}>
        {projects.map((project) => (
          <div
            className={styles.thumbnailLayout}
            onClick={() => handleProjectClick(project)}
          >
            <img className={styles.thumbnailImage} src={project.thumbnail} />
            <p className={styles.thumbnailTitle}>{project.name}</p>
          </div>
        ))}
      </div>
      {showModal && (
        <ProjectInfoModal project={projectInfo} closeModal={closeModal} />
      )}
    </div>
  );
};

export default ProjectsTiledView;
