import styles from "components/Contact/Contact.module.scss";

const handleGoToTopClick = () => {
  window.scroll({
    top: 0,
    left: 0,
    behavior: "smooth",
  });
};

const Contact = () => {
  return (
    <div className={styles.layout}>
      <div className={styles.topLayout}>
        <p className={styles.label1}>Contact</p>
        <div className={styles.labelsLayout}>
          <p className={styles.label2}>Would you like to contact me?</p>
          <p className={styles.label2}>Mobile: 8105570945</p>
          <p className={styles.label2}>
            Email Id:{" "}
            <a
              href="mailto:gangadharshetti.gs@gmail.com"
              className={styles.anchor}
            >
              gangadharshetti.gs@gmail.com
            </a>
          </p>
        </div>
      </div>
      <div className={styles.bottomLayout}>
        <div className={styles.bioLinks}>
          <a
            href="www.linkedin.com/in/gangadhar-shetti-90bb88122"
            target="_blank"
            rel="noreferrer"
          >
            <img className={styles.linkIcon} src={"/images/bio-linkedin.svg"} />
          </a>
          <a
            href="https://github.com/ShettiGangadhar"
            target="_blank"
            rel="noreferrer"
          >
            <img className={styles.linkIcon} src={"/images/bio-github.svg"} />
          </a>
        </div>
        <p className={styles.buttonGoToTop} onClick={handleGoToTopClick}>
          Go to top
        </p>
      </div>
    </div>
  );
};

export default Contact;
