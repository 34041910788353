import styles from "components/Intro/Intro.module.scss";

const Intro = (props) => {
  return (
    <div className={styles.nameLayout}>
      <p className={styles.intro}>
        Hi, my name is <span className={styles.name}>Gangadhar</span> <br /> I'm
        a Software Developer.
      </p>
      {/* <button className={styles.knowMore} onClick={props.executeScroll}>
        Know more
      </button> */}
    </div>
  );
};

export default Intro;
