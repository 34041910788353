import styles from "components/ImageSlider/ImageSlider.module.scss";
import { useState } from "react";

const ImageSlider = (props) => {
  const [index, setIndex] = useState(0);

  if (props.images === undefined || props.images.length === 0) {
    return (
      <img
        className={styles.landscapeImage}
        src={"/images/projects-no-image-available.jpg"}
      />
    );
  }

  const image = props.images[index];

  let style;
  if (image.type === "landscape") {
    style = styles.landscapeImage;
  } else {
    style = styles.portraitImage;
  }
  return <img className={style} src={image.path} />;
};

export default ImageSlider;
