import styles from "components/About/About.module.scss";

const handleResumeClick = () => {};

const About = (props) => {
  return (
    <div ref={props.myRef} className={styles.aboutLayout}>
      <div className={styles.aboutMe}>
        <img src={"/images/profile.png"} className={styles.image} />
        <p className={styles.buttonResume} onClick={handleResumeClick}>
          Resume
        </p>
      </div>
      <div className={styles.columnLayout}>
        <p className={styles.title}>ABOUT ME</p>
        <p className={styles.contentAboutMe}>
          I'm from{" "}
          <a
            href="https://www.google.com/maps/place/Ankola,+Karnataka/@14.6610589,74.2716654,13z/data=!3m1!4b1!4m5!3m4!1s0x3bbe860f3678c4a1:0x961711e159e04ede!8m2!3d14.6653087!4d74.3000878"
            target="_blank"
            rel="noreferrer"
            className={styles.anchor}
          >
            Ankola.
          </a>
        </p>
        <p className={styles.contentAboutMe}>
          Currently working full-time with{" "}
          <a
            href="https://www.nichesoft.net/"
            target="_blank"
            rel="noreferrer"
            className={styles.anchor}
          >
            Nichesoft.
          </a>
        </p>
        <p className={styles.contentAboutMe}>
          as a web developer most of the time specializing in React & Next.js
        </p>
        <p className={styles.contentAboutMe}>
          Also worked on Android application developement for more than 2 years
          (full-time & freelance)
        </p>
        <p className={styles.contentAboutMe}>
          AWS piqued my interest recently and I like working on it.
        </p>
        <p className={styles.contentAboutMe}>
          Interested to work on new ideas and platforms.
        </p>
      </div>
    </div>
  );
};

export default About;
